<template>
  <div class="box">
    <h2>Demo</h2>
    <Field
      v-model="keySecret"
      name="SecureKey"
      label="SecureKey"
      placeholder="SecureKey"
    ></Field>
    <Field
      v-model="form.txnRefId"
      name="txnRefId"
      label="txnRefId"
      maxlength="50"
      placeholder="txnRefId"
    >
    <template #button>
      <Button type="warning" @click="form.txnRefId = randomString()">Refresh</Button>
    </template>
  </Field>
    <Field
      v-model="form.partnerId"
      name="partnerId"
      label="partnerId"
      maxlength="50"
      placeholder="partnerId"
    ></Field>
    <Field
      v-model="form.customerName"
      name="customerName"
      label="customerName"
      maxlength="30"
      placeholder="customerName"
    ></Field>
    <Field
      v-model="form.amount"
      name="amount"
      label="amount"
      type="number"
      placeholder="amount"
    ></Field>
    <Field
      v-model="form.productName"
      name="productName"
      label="productName"
      maxlength="30"
      placeholder="productName"
    ></Field>
    <Field
      v-model="form.paymentRef"
      name="paymentRef"
      label="paymentRef"
      maxlength="30"
      placeholder="paymentRef"
    ></Field>
    <Field
      v-model="form.resultCallbackUrl"
      name="resultCallbackUrl"
      label="resultCallbackUrl"
      maxlength="200"
      placeholder="resultCallbackUrl"
    ></Field>
    <Field
      v-model="form.jumpUrlAfterPayment"
      name="jumpUrlAfterPayment"
      label="jumpUrlAfterPayment"
      maxlength="200"
      placeholder="jumpUrlAfterPayment"
    ></Field>
    <br />
    <div class="btn">
      <Button type="primary" @click="handleId">Generate OnetimeId</Button>
      <Button type="success" @click="handleDecrypt">Decrypt</Button>
      <Button type="warning" @click="forWord">Jump link</Button>
    </div>
    <br />
    <h2>Input</h2>
    <Field
      v-model="str"
      rows="4"
      autosize
      label="Input"
      type="textarea"
      placeholder="Please input"
    />
    <div class="btn">
      <Button type="primary" @click="handleEncrypt">Encrypt</Button>
      <Button type="success" @click="handleDecrypt">Decrypt</Button>
    </div>

    <h2>Output</h2>
    <Field
      v-model="outStr"
      rows="4"
      autosize
      readOnly
      label="Output"
      type="textarea"
    />
  </div>
</template>

<script setup>
import {reactive, ref} from "vue";
import {useRouter} from "vue-router";
import request from "@/utils/request";

let router = useRouter();

const {
  base64_to_bytes,
  bytes_to_base64,
  string_to_bytes,
  bytes_to_string,
  AES_GCM,
} = asmCrypto;
// 密钥
let keySecret = ref("1NJD7KIFpAmfT8JJEj+yFH7hAbApZMuWvKPeLkkI4lw=");
let form = reactive({
    txnRefId: '',
    partnerId: "MPA04S000010",
    customerName: "Payment Test",
    customerMobile: "9123456780",
    amount: (Math.random()*50).toFixed(2),
    productName: "Maintenance",
    paymentRef: "test",
    resultCallbackUrl: "http://192.168.10.107/mock/mch/callback/receive",
    jumpUrlAfterPayment: "https://www.baidu.com",
})
// 输入
let str = ref("");

// 输出
let outStr = ref("");

const base64_req_iv = "h/yB5x7CQByyq5JR";

const req_iv = base64_to_bytes(base64_req_iv);
// 加密
const encrypt = (key, input) => {
  const in_bytes = string_to_bytes(input);
  const aes = new AES_GCM(key, req_iv, undefined, 16);
  const en_data = aes.encrypt(in_bytes);
  return base64_req_iv + bytes_to_base64(en_data);
};
// 解密
const decrypt = (key, input) => {
  const base64_resp_iv = input.substring(0, 16);
  const base64_data = input.substring(16);
  const resp_iv = base64_to_bytes(base64_resp_iv);
  const data = base64_to_bytes(base64_data);
  const aes = new AES_GCM(key, resp_iv, undefined, 16);
  return bytes_to_string(aes.decrypt(data));
};

// 加密触发
const handleEncrypt = () => {
  const key = base64_to_bytes(keySecret.value);
  outStr.value = encrypt(key, str.value);
};

// 解密触发
const handleDecrypt = () => {
  const key = base64_to_bytes(keySecret.value);
  outStr.value = decrypt(key, str.value);
};

// 生成随机数
const randomString = (e) => {
  e = e || 32;
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length;
  let n = "";
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a));
  }
  return n;
};
form.txnRefId = randomString();

// 生成onetimeId
const handleId = () => {
  const key = base64_to_bytes(keySecret.value);
  const data = form;
  request({
    url: "/h5/openapi/v1/pg-gateway/collection",
    method: "post",
    data: encrypt(key, JSON.stringify(data)),
    headers: {
      "X-MERCHANT-PARTNER-ID": form.partnerId,
      "Content-Type": "text/plain",
    },
  }).then((code) => {
    str.value = code;
  });
};
const forWord = () => {
  let onetimeId = JSON.parse(outStr.value).data.onetimeId || '';
  router.replace({
    path: "/",
    query: {
      onetimeId: onetimeId,
    },
  });
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin: 20px 50px;
    button {
      width: 48%;
    }
  }
}
</style>
